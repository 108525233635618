import React, {useState } from '../../../node_modules/react';
import './Header.css'
import { useTranslation } from 'react-i18next';
import { useScrollPosition } from '@n8tb1t/use-scroll-position'

function Header() {
    const { t, i18n } = useTranslation();
    var headerItems = t('header', { returnObjects: true }).map((item, index, arr)=> {
        var highlighted = '';
        if (arr.length === index + 1) {
             highlighted = '-highlighted'
          }
        
        return <div key={index} className="header__menu-item">
        <a href={'#'+item.sectionId} className={'header__menu-link -skin-white '+highlighted} onClick={() => setActive(!isActive)} >
                {item.name}
            <span className="js-hover__line complex-link__line"></span>
        </a>
        </div>
        
    })
    const [isActive, setActive] = useState(false)
    const [upscrolled, setUpscrolled] = useState('');
    const [headerTransform, setheaderTransform] = useState({
        transform: 'translateY(0px)'
      })
    
    useScrollPosition(({ prevPos, currPos }) => {
        const headerHeight = -100;
        var setPosition = 0;

        if(currPos.y < headerHeight){
            setUpscrolled(' -upscrolled')
        } else if (currPos.y > headerHeight){
            setUpscrolled('')
        }
        if(prevPos.y < currPos.y){
            setPosition = 0;
        }else if(prevPos.y > currPos.y){
            if(currPos.y < -200){
                setPosition = headerHeight;
            }else{
                setPosition = currPos.y;
            }
        }

        const transformation = {
            transform: `translateY(${setPosition +'px'}`
        }
        setheaderTransform(transformation);
      },
      [headerTransform]
    )

    // $("#myNavbar a").on('click', function(event) {
    //     // Make sure this.hash has a value before overriding default behavior
    //     if (this.hash !== "") {
    //     // Prevent default anchor click behavior
    //     event.preventDefault();

    //     // Store hash
    //     var hash = this.hash;

    //     // Using jQuery's animate() method to add smooth page scroll
    //     // The optional number (800) specifies the number of milliseconds it takes to scroll to the specified area
    //     $('html, body').animate({
    //         scrollTop: $(hash).offset().top
    //     }, 800, function(){

    //         // Add hash (#) to URL when done scrolling (default click behavior)
    //         window.location.hash = hash;
    //     });
    //     }  // End if
    // });
    const logoUrlWhite = require('../../static/img/logo-white.svg')
    const logoUrlBlakc = require('../../static/img/logo-black.svg')

        return(
            <header className="header " className={isActive ? 'header -active' : 'header'+upscrolled} style={{... headerTransform}}>
            <div className="container">
                <div className="header__container">
                    <div className="header__logo">
                        <img src={upscrolled ? logoUrlBlakc: logoUrlWhite } alt="" class="header-logo" data-was-processed="true"/>
                    </div>

                    <button type="button" className="header__burger  -white "  onClick={() => setActive(!isActive)} > 
                    <span className="ico -cross-thin">
                    </span>
                    </button>
                    <div className="header__overlay"></div>
                    <div className="header__fader"></div>
                    <div className="header__menu">
                        <div className="header__menu-links">
                            <div className="header__menu-item" >
                                <a href="#" className="js-hover complex-link -skin-white" onClick={() => setActive(!isActive)} >
                                    ↑
                                    <span className="js-hover__line complex-link__line"></span>
                                </a>
                            </div>
                           {headerItems}
                        </div>
                    </div>
                </div>
            </div>
        </header>            
        )
    }

export default Header;

import React, { Component , Suspense} from 'react'
import './App.css';
import Header from '../Header/Header'
import Main from '../Main/Main'
import Footer from '../Footer/Footer'


class App extends Component {
  render() {
    return (
      <React.Fragment>
         <Suspense fallback={<Loader />}>
          <Header /> 
          <Main /> 
          <Footer />
        </Suspense>

      </React.Fragment>
    );
  }
  
}

const Loader = () => (
  <div className="App">
    <div>loading...</div>
  </div>
);

export default App;

import i18n from 'i18next'
import Backend from 'i18next-xhr-backend'
import { initReactI18next } from 'react-i18next'


import LanguageDetector from 'i18next-browser-languagedetector';


// var options = {
//   // order and from where user language should be detected
//   order: ['querystring', 'cookie', 'localStorage', 'navigator', 'htmlTag', 'path', 'subdomain'],

//   // keys or params to lookup language from
//   lookupQuerystring: 'lng',
//   lookupCookie: 'i18next',
//   lookupLocalStorage: 'i18nextLng',
//   lookupFromPathIndex: 0,
//   lookupFromSubdomainIndex: 0,

//   // cache user language on
//   caches: ['localStorage', 'cookie'],
//   excludeCacheFor: ['cimode'], // languages to not persist (cookie, localStorage)

//   // // optional expire and domain for set cookie
//   // cookieMinutes: 10,
//   // cookieDomain: 'myDomain',
 
//   // optional htmlTag with lang attribute, the default is:
//   htmlTag: document.documentElement,

//   // only detect languages that are in the whitelist
//   checkWhitelist: true
// }

i18n
  .use(Backend)
  .use(initReactI18next)
  .use(LanguageDetector)
  .init({
    // detection: options,
    // lng: 'pl',
    backend: {
      /* translation file path */
      loadPath: '/assets/i18n/{{ns}}/{{lng}}.json'
    },
    fallbackLng: 'en',
    debug: true,
    /* can have multiple namespace, in case you want to divide a huge translation into smaller pieces and load them on demand */
    ns: ['translations'],
    defaultNS: 'translations',
    keySeparator: false,
    interpolation: {
      escapeValue: false,
      formatSeparator: ','
    },
    react: {
      wait: true,
      useSuspense: true // failing on default true
    }
  })

export default i18n

// i18n
//   .use(Backend)
//   .use(initReactI18next)
//   .init({
//     lng: 'en',
//     backend: {
//       /* translation file path */
//       loadPath: '/assets/i18n/{{ns}}/{{lng}}.json'
//     },
//     fallbackLng: 'en',
//     debug: true,
//     /* can have multiple namespace, in case you want to divide a huge translation into smaller pieces and load them on demand */
//     ns: ['translations'],
//     defaultNS: 'translations',
//     keySeparator: false,
//     interpolation: {
//       escapeValue: false,
//       formatSeparator: ','
//     },
//     react: {
//       wait: true,
//       useSuspense: true // failing on default true
//     }
//   })

// export default i18n
import app from 'firebase/app';
import FirebaseContext from './context';

const config = {
    apiKey: "AIzaSyC0-E5FSLcOaF9VozGO3ftNZMmAmw1L3Qk",
    authDomain: "dbitcompanypage.firebaseapp.com",
    databaseURL: "https://dbitcompanypage.firebaseio.com",
    projectId: "dbitcompanypage",
    storageBucket: "dbitcompanypage.appspot.com",
    messagingSenderId: "980657704765",
    appId: "1:980657704765:web:28493cc40e3c6b235cbf54",
    measurementId: "G-9MXWP83M3D"
};
class Firebase {
  constructor() {
    app.initializeApp(config);
    // app.analytics();
  }
}
export default Firebase;
export { FirebaseContext };

import React from 'react';
import './Services.css'
import Cart from './Cart/Cart'
import { useTranslation } from 'react-i18next';


function Services() {
    const { t, i18n } = useTranslation();
  
    var cartList = t('cartList', { returnObjects: true }).map((item, index)=> {
        return <Cart key={index} cartInfo={item}/>
    })

  return (
    <div>
        <section id="services" class="section">
            <div class="container">
                <header class="section__header">
                    <div class="headline">
                        <span class="headline__title">
                            {t('services.label')}
                        </span>                      
                    </div>
                </header>
                <div class="section__body">
                    <div class="grid -three -two-tablet">
                        {cartList}
                    </div>
                </div>
            </div>
        </section>
    </div>
  )
}

export default Services;
import React from '../../../../../node_modules/react';
import './Cart.css'

function Cart({cartInfo}) {    
    const imgUrl = require('../../../../static/img/'+cartInfo.iconName)
    
    return (

        <div class="grid__row">
        <div class="service-tile js-hover">
            <span class="service-tile__icon -design">
                <img src={imgUrl} alt="" class="loading" data-was-processed="true"/>

            </span>
            <span class="service-tile__title">{cartInfo.label}</span>
            <span class="service-tile__desc js-hover__container">
            <span class="service-tile__desc-lines js-hover__line -multi">
            </span>
            <span class="js-hover__rect">{cartInfo.description}</span>
            </span>
        </div>
    </div>
    )
}


export default Cart;

import React from 'react';
import Snackbar from '@material-ui/core/Snackbar';
import './Footer.css'
import useForm from '../Hooks/Form';
import { useTranslation } from 'react-i18next';
import * as firebase from 'firebase';

function Footer(){
  const { t, i18n } = useTranslation();
  const [open, setOpen] = React.useState(false);
  const handleClose = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }
    setOpen(false);
  };
  const formSubmitCallback = () => {
    var newsletterRef = firebase.database().ref('/newsletter')
    var newNewsletterRef = newsletterRef.push();
    newNewsletterRef.set (
      {
        sendemail: true,
        email: inputs.email,
        approvedTC: t('footer', { returnObjects: true }).newsletterApproval
      }
    )
    setOpen(true);
    document.getElementById("newsletterRequest").reset();

  }
  const {inputs, handleInputChange, handleSubmit} = useForm(formSubmitCallback);
  const logoUrl = require('../../static/img/logo-black.svg')

  
  return(

<footer class="page-footer font-small unique-color-dark">

  <div class="container text-center text-md-left mt-5">

    <div class="row mt-3">
        <div class="col-md-3 col-lg-4 col-xl-3 mx-auto mb-4">
            <img src={logoUrl} alt="" class="footer-logo" data-was-processed="true"/>

            {/* <h6 class="text-uppercase font-weight-bold">{t('footer', { returnObjects: true }).companyName}</h6> */}
            <p>{t('footer', { returnObjects: true }).companyInfo}</p>
        </div>

      <div class="col-md-4 col-lg-3 col-xl-3 mx-auto mb-md-0 mb-4">
        <h6 class="text-uppercase font-weight-bold">
          {t('footer', { returnObjects: true }).contactHeader}
        </h6>
        {t('footer', { returnObjects: true }).contactInfoList.map((item, index)=> {
                return <p key={index}>{item.name}</p>
        })}

      </div>
        
      <div class="col-md-4 col-lg-3 col-xl-3 mx-auto mb-md-0 mb-4">
        <form id="newsletterRequest" onSubmit={handleSubmit}>
          <h6 class="text-uppercase font-weight-bold">
            {t('footer', { returnObjects: true }).newsletterHeader}
          </h6>
          <div class="contact-input-element">
              <input
                  onChange ={handleInputChange}
                  class = "footer-newsletter-input"
                  label="tests"
                  placeholder="Email"
                  name="email"
                  type="email"
                  required
              />
              <button type="submit" class="button-newsletter section-gradient">
                  {t('footer', { returnObjects: true }).newsletterSendButton}
              </button>
              <div class="newsletter-approval">
                <label>
                  <input 
                  onChange ={handleInputChange}
                  type="checkbox" 
                  name="approval" 
                  required/>
                  {t('footer', { returnObjects: true }).newsletterApproval}
                </label>
              </div>
          </div>
        </form>
      </div>
    </div>
  </div>
  <Snackbar
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'center',
        }}
        open={open}
        autoHideDuration={6000}
        onClose={handleClose}
        message= {t('footer', { returnObjects: true }).submitToast}
      />

</footer>
    )
}

export default Footer;

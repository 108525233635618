import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import App from './components/App/App';
import registerServiceWorker from './registerServiceWorker';
import './i18n';

import Firebase, { FirebaseContext } from './components/Firebase/Firebase';

// ReactDOM.render(<App />, document.getElementById('root'));

ReactDOM.render(
    <FirebaseContext.Provider value={new Firebase()}>
      <App />,
    </FirebaseContext.Provider>,
    document.getElementById('root'),
  );
  
registerServiceWorker();

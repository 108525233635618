import React from '../../../node_modules/react';
import '../../../node_modules/bootstrap/dist/css/bootstrap.min.css'
import '../../../node_modules/jquery/dist/jquery.js'
import { useTranslation } from 'react-i18next';

import './Main.css'
// import Approach from './Approach/Approach'
import Services from './Services/Services'
import Solutions from './Solutions/Solutions'
import ContactUs from './ContactUs/ContactUs'

function Main () {

    const { t, i18n } = useTranslation();

    return(

    <main class="main">
        <section id="mission" class="section-gradient">
            <div class="container">
                <div class="section-gradient__container">
                    <div class="section-gradient__headlines">
                        <h1>                               
                             {t('mission', { returnObjects: true}).main}
                        </h1>
                    </div>
                    <div class="section-gradient__text">
                        <div class="text -size-bigger">
                            <p>
                                {t('mission', { returnObjects: true}).info}
                            </p>
                        </div>
                    </div>
                </div>
            </div>
        </section>
        <div class="section-separation"></div>

            <Services />
            <div class="section-separation"></div>
            <Solutions />
            <div class="section-separation"></div>
            <ContactUs />

        {/* <Approach /> */}
    </main>

        )
    }


export default Main;

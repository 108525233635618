import React from 'react';
import Snackbar from '../../../../node_modules/@material-ui/core/Snackbar';
import './ContactUs.css'
import { useTranslation } from 'react-i18next';
import * as firebase from 'firebase';
import useForm from '../../Hooks/Form';


function ContactUs() {
  const { t, i18n } = useTranslation();
  //shackbar
  const [open, setOpen] = React.useState(false);
  const handleClose = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }
    setOpen(false);
  };

  const formSubmitCallback = () => {
    var contactRef = firebase.database().ref('/contacts')
    var newContactRef = contactRef.push()
      
    newContactRef.set (
      {
        firstName: inputs.firstName,
        lastName: inputs.lastName,
        email: inputs.email,
        phone: inputs.phone,
        question: inputs.question,
        approvals: t('contactForm', { returnObjects: true }).approvalList
      }
    );
    setOpen(true);
    document.getElementById("contactUsForm").reset();

  }
  const {inputs, handleInputChange, handleSubmit} = useForm(formSubmitCallback);


  var contactInputList = t('contactForm', { returnObjects: true }).inputFields.map((item, index)=> {
    return <div key={index} class="contact-input-element">
      <input
          onChange ={handleInputChange}
          class = "input-contact input-contact-text"
          placeholder={item.placeholder}
          name={item.name}
          type={item.type}
          required
      />
    </div>
  });

  var approvals = t('contactForm', { returnObjects: true }).approvalList.map((item, index)=> {

      return <div key={index} class="newsletter-approval">
          <label>
            <input 
              onChange ={handleInputChange}
              type="checkbox" 
              name={item.name} 
              required
            />
            {item.content}
          </label>
        </div>
  });

  
  return (
    <div>
        <section id="contact" class="section-gradient">
            <div class="container">
                <div class="contact-container">
                  <div class="contact-cart">
                    <div class="approach">
                        <div class="contact__headline">
                            <h2 >
                            {t('contactForm', { returnObjects: true }).header}

                            </h2>
                        </div>
                    </div>
                    <form id="contactUsForm" onSubmit={handleSubmit}>
                          <div class="contact-input-container">
                            {contactInputList}
                            <div class="contact-input-element">
                              <button type="submit" class = "input-contact input-contact-button" > 
                                {t('contactForm', { returnObjects: true }).sendButton}
                              </button>
                            </div>
                            {approvals}
                          </div>
                      </form>
                  </div>
                </div>
            </div>
        </section>
      <Snackbar
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'center',
        }}
        open={open}
        autoHideDuration={6000}
        onClose={handleClose}
        message= {t('contactForm', { returnObjects: true }).submitToast}
      />

    </div>
    
  )
}

export default ContactUs;


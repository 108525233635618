import React from '../../../../node_modules/react';
import './Solutions.css'
import Silo from './Silo/Silo'
import { useTranslation } from 'react-i18next';


function Solutions() {
    const { t, i18n } = useTranslation();
    
    // var siloList = t('solutions', { returnObjects: true }).siloList.map((item, index)=> {
    //     return <Silo key={index} item={item}/>
    // })

    
    var solutionList = t('solutions', { returnObjects: true }).siloList.map((item, index)=> {
        
        var technologyList = item.technologies.map((tech, techIndex) => {
           return <div key={techIndex} class="small-cart col-sm-4 col-md-3 ">{tech.name}</div>
        })
        var styles = {
            height: item.iconHeight
        }
        
        var element =   
                        <div key={index} class="container">
                            <div class="row technology-header">
                                <div class="col">
                                    <span class="solutions-tile__icon -design" >
                                        <img src={require('../../../static/img/'+item.iconName)} alt="" class="loading" data-was-processed="true" style={styles}/>
                                    </span>
                                </div>
                                <div class="col technology-header-title">
                                    {item.name}
                                </div>
                                <div class="col">
                                </div>

                            </div>
                            
                            <div class="row justify-content-center technology-list">                                                        
                                <div class="container">
                                    <div class="row justify-content-center">
                                    {technologyList}
                                    </div>
                                </div>
                            </div>

                        </div>
                        

        return element;
    })

    return(
            <div>
            <section id="solutions" class="section">
                <div class="container">
                    <header class="section__header">
                        <div class="headline">
                            <span class="headline__title">
                                {t('solutions', { returnObjects: true}).label}
                            </span>                      
                        </div>
                    </header>                   
                </div>
            </section>
            <div>
                {solutionList}
            </div>
        </div>
    )
}

export default Solutions;
